import '../stylesheets/application'

import 'jquery'
import 'popper.js'
import 'bootstrap'

import '../javascripts/init'

import '../javascripts/styxies/main_page'

require.context('../images', true)
