import { Toast } from 'javascripts/common/swal'

const hsh = {
  notice: 'success',
  error: 'error',
  info: 'info',
  alert: 'warning'
}

export default function showFlashes() {
  $('.flashes').children().each((_, el) => {
    const level = hsh[el.dataset.key] || 'info'
    Toast.fire(el.textContent, '', level)
    el.remove()
  })
}
