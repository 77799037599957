import showFlashes from 'javascripts/common/flashes'

require('@rails/ujs').start()
require('turbolinks').start()
require('javascripts/channels')

$(document).on('turbolinks:load', () => {
  showFlashes()
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover({ trigger: 'hover' })
})
